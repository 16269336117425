import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { PageLayout } from '../layouts'
import { filterPublishedEdges } from '../utils/gatsby'
import variables from '../styles/variables'
import * as styles from '../styles/pages/blog'
import {
  PageHeader,
  BlogList,
  Section,
  SimpleCallToActionBanner,
} from '../components'

const propTypes = {
  data: PropTypes.object.isRequired
}

export default function BlogPage({ data, pageContext }) {
  const { edges } = data.allMdx
  const contentEdge = _.first(data.allYaml.edges)
  const content = contentEdge.node
  const publishedEdges = filterPublishedEdges(edges)
  const blogs = _.map(publishedEdges, e => e.node)
  const { tag } = pageContext
  const kebabTag = _.kebabCase(tag)
  const baseUrl = `/blog/tags/${kebabTag}/`
  const { numPages, currentPage } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? baseUrl : `${baseUrl}${currentPage - 1}`
  const nextPage = `${baseUrl}${currentPage + 1}`
  const paginationOpts = {
    numPages, currentPage, isFirst, isLast, prevPage, nextPage
  }

  const firstChild = (
    <PageHeader
      header={content.header}
      info={content.headerInfo}
    />
  )

  return (
    <PageLayout
      firstChild={firstChild}
      navbarProps={{ background: variables.colors.oracle.dark }}
      description={content.metaDescription}
      sharingTitle={content.title}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{content.title}</title>
      </Helmet>
      <Section>
        <styles.Container>
          <styles.StyledLink to="/blog">
            <styles.BackArrow />
            Back to blog list
          </styles.StyledLink>
          <BlogList blogs={blogs} paginationOpts={paginationOpts} baseUrl={baseUrl} />
        </styles.Container>
      </Section>
      <SimpleCallToActionBanner />
    </PageLayout>
  )
}

BlogPage.propTypes = propTypes

export const blog = graphql`
  query GetAllBlogByTags($tag: String, $skip: Int!, $limit: Int!) {
    allMdx(
      filter: {frontmatter: { path: { regex: "^/blog/" }, tags: { in: [$tag] } }}
      sort: {fields: [frontmatter___date], order: DESC}
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            path
            title
            introduction
            tags
            coverImgLink
            date
            publish
          }
          coverImg {
            childImageSharp {
              fluid(maxWidth: 330, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    },
    allYaml(filter: {file: {eq: "blog"}}) {
      edges {
        node {
          title
          header
          headerInfo
          metaDescription
        }
      }
    }
  }
`
