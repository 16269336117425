import _ from 'lodash'
import config from '../config'

export function filterPublishedEdges(edges) {
  if (config.GATSBY_TARGET_ENV !== 'production') {
    return edges
  }

  return _.filter(edges, e => e.node.frontmatter.publish)
}
