import styled from 'styled-components'
import { Link } from 'gatsby'
import { ArrowLeft } from '@styled-icons/feather'
import { Heading } from '../../components'
import { headerStyles } from '../global'
import variables from '../variables'

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 50px auto 0 auto;
  padding: 0 30px;
  @media ${variables.media.md} {
    padding: 0 60px;
  }
`

export const TagHeader = styled(Heading)`
  ${headerStyles.headerXSmall};
  font-family: ${variables.fonts.body};
  font-weight: normal;
  margin-left: 20px;
`

export const StyledLink = styled(Link)`
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 20px;
`
export const BackArrow = styled(ArrowLeft)`
  width: 16px;
  height: 16px;
  margin-right: 5px;
`
